<template>
  <VCard>
    <VToolbar flat>
      <VBtn
        icon
        @click="$router.go(-1)"
      >
        <VIcon>fal fa-chevron-left</VIcon>
      </VBtn>

      <VToolbarTitle>
        Просмотр штатной должности
      </VToolbarTitle>
    </VToolbar>

    <VDivider />

    <VCardText>
      <VExpansionPanels
        accordion
        flat
      >
        <VExpansionPanel>
          <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
          <VExpansionPanelContent>
            <code class="elevation-0 d-block">{{ staffPosition }}</code>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCardText>
  </VCard>
</template>

<script>
export default {
  name: 'StaffPositionCard',
  props: {
    staffPosition: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
