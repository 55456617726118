<template>
  <TTView>
    <VRow>
      <VCol>
        <StaffPositionCard :staff-position="staffPosition" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import StaffPositionCard from '../../components/org/StaffPositionCard.vue';

export default {
  name: 'OrgPositionView',
  components: {
    StaffPositionCard,
  },
  data() {
    return {
      staffPosition: {},
    };
  },

  computed: {
    positionId() {
      return this.$route.params.positionId;
    },
  },
  async created() {
    await this.fetch();
  },

  methods: {
    fetch() {
      this.$di.api.Orgstructure
        .positionShow({ id: this.positionId })
        .then((response) => {
          this.staffPosition = response;
        })
        .catch((err) => {
          this.$di.notify.errorHandler(err);
          this.$di.redirect.errorHandler404(err);
        });
    },
  },
};
</script>
